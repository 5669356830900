html {
  // WebKit-based browsers
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    border: 3px solid #2e2e2e;
  }

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(143, 143, 143, 0.4) rgba(0, 0, 0, 0.1);
}
