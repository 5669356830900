.contexify_theme-dark {
  --contexify-menu-bgColor: #32303f;
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #81b8fc;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}
